import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import bulmaCarousel from 'bulma-carousel'
import Img from 'gatsby-image'
import ShowPerformances from '../components/ShowPerformances'
import ContentPadding from '../components/ContentPadding'
import Review from '../components/Review'
import ColoredHeader from '../components/ColoredHeader'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    const { data } = props;
    
    const posts = data.posts.edges.map(({ node: post }) => post);
    const shows = data.shows.edges.map(({ node: show }) => show).map(show => {
      if (show.frontmatter.performances) {
        var now = new Date();
        show.future_perfs = show.frontmatter.performances.filter(p => p.date && new Date(p.date) > now);
      }
      
      return show;
    });
    
    const future_shows = shows.filter(s => s.future_perfs && s.future_perfs.length);

    var hero_posts = shows.filter(this.frontpage_only);
    
    const reviews = shows.filter(s => s.frontmatter.reviews && s.frontmatter.reviews).map(s => s.frontmatter.reviews).reduce((a,b) => a.concat(b), []);
    
    this.state = {posts: posts, shows: shows, hero_posts: hero_posts, future_shows: future_shows, reviews: reviews};
  }

  frontpage_only = p => p.frontmatter && p.frontmatter.full_image && p.frontmatter.homepage_featured === true;

  componentDidMount() {
    if (this.state.hero_posts.length > 1){
      bulmaCarousel.attach();
    }
  }

  render() {
    return (
      <Layout addScrolledClass={true}>
        <section className="hero is-full-height has-carousel">
          <div className="hero-carousel carousel-animated carousel-animate-fade" data-autoplay="false">
            <div className="carousel-container">
              {this.state.hero_posts
                .map((post, i) => (
                  <div key={i} className={'carousel-item has-background' + (i ? '' : 'carousel-item has-background is-active')} >
                    <Img className={'is-background crop-focus-' + post.frontmatter.header_image_crop_focus} fluid={post.frontmatter.full_image.childImageSharp.fluid} alt="" />
                    
                    <Link className="title" to={post.fields.slug}>
                      {post.frontmatter.title}
                    </Link>
                  </div>
              ))}
            </div>
            {this.state.hero_posts.length > 1 ? <div className="carousel-navigation is-overlay">
              <div className="carousel-nav-left">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
              <div className="carousel-nav-right">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </div> : null }
          </div>
          
        </section>
        
        <ContentPadding>
          <section className="section">
            <div className="columns is-centered is-multiline">
             <div className="column is-one-third has-text-centered">
              <Link to="/shows"><h3 className="is-size-3">Shows</h3></Link>
              <div className="content">
                Explore our original shows that uniquely combine music and history in immersive theatre
              </div>
              <Link className="button has-background-highlight has-text-white" to="/shows">Shows</Link>
             </div>
             <div className="column is-one-third has-text-centered bordered-middle-column">
              <Link to="/outreach"><h3 className="is-size-3">Outreach</h3></Link>
              <div className="content">
                Find out more information about our interactive workshops in schools and care homes
              </div>
              <Link className="button has-background-highlight has-text-white" to="/outreach">Outreach</Link>
             </div>
             <div className="column is-one-third has-text-centered">
              <Link to="/support-us/shop"><h3 className="is-size-3">Shop</h3></Link>
              <div className="content">
                Shop here for all your limited edition Voices Across Time merchandise
              </div>
              <Link className="button has-background-highlight has-text-white" to="/support-us/shop">Shop</Link>
             </div>
            </div>
          </section>
        </ContentPadding>
        
        {this.state.future_shows && this.state.future_shows.length ? (
          <React.Fragment>
            <ColoredHeader>Upcoming Shows</ColoredHeader>
            <ContentPadding>
              <section className="section">
                <ShowPerformances shows={this.state.future_shows} />
              </section>
            </ContentPadding>
          </React.Fragment>
          )
        : null}
        
        <Review reviews={this.state.reviews} />
          
        {this.state.posts && this.state.posts.length ? (
          <ContentPadding>
            <section className="section">
              <div>
                <h1 className="has-text-weight-bold is-size-2">Latest News</h1>
              </div>
              <div className="columns is-centered is-multiline">
              {this.state.posts
                .map((post, i) => (
                  <div className="column is-one-third" key={post + post.id}>
                    <div className="card" style={{height: "100%"}}>
                      <Link className="card-image" to={post.fields.slug}>
                        <Img fluid={post.frontmatter.small_image.childImageSharp.fluid} />
                      </Link>
                      <div className="card-content blog-card-content">
                        <div className="media">
                          <div className="media-content">
                            <p className="title is-size-4"><Link className="has-text-primary" to={post.fields.slug}>
                              {post.frontmatter.title}
                            </Link></p>
                            <p className="subtitle is-size-6">{post.frontmatter.date}</p>
                          </div>
                        </div>
                        <div className="content has-text-justified">
                          {post.excerpt}
                          
                          <Link className="button is-small has-background-highlight has-text-white is-pulled-right" to={post.fields.slug}>Read More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </ContentPadding>)
        : null}

      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" }, published: { eq: true } }}
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            small_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300, quality: 75, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    shows: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "show" }, published: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            full_image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            small_image {
              childImageSharp {
                small: fluid(maxWidth: 400, maxHeight: 565, quality: 75, cropFocus: CENTER, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                full: fluid(maxWidth:1344, quality: 90, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            header_image_crop_focus
            homepage_featured
            performances{
              date
              location
              ticket_cost
              ticket_types {
                type
                price_change
              }
            }
            reviews{
              review
              author
            }
          }
        }
      }
    }
  }
`
